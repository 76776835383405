<template>
  <div class="login">
    <div class="loginLogo"></div>
    <div class="formContent">
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="form"
        label-position="top"
        label-width="60px"
        class="formBody"
      >
        <p
          style="color: white; width: 120px; text-align: left; font-size: 20px"
        >
          hi !!!
        </p>
        <ul class="title">
          <li>欢迎登录</li>
          <li>未来战场 —— 排队系统</li>
        </ul>
        <el-form-item prop="email">
          <el-input
            placeholder="账号"
            v-model="loginForm.email"
            class="inputColor"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="loginForm.password"
            placeholder="密码"
            class="inputColor"
            @keyup.enter.native="login"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="login"
            :loading="loading"
            class="loginButton"
            >登陆</el-button
          >
        </el-form-item>
      </el-form>
      <p class="titleBot">— 未来战场 Fature Battle —</p>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRefs, unref } from "vue";
import service from "@/utils/api";
import method from "@/utils/method";
import { useRouter } from "vue-router";
import { onMounted } from "@vue/runtime-core";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const router = useRouter();
    const form = ref(null);
    const state = reactive({
      loading: false,
      loginForm: {
        email: "",
        password: "",
      },
      information: [],
    });
    const rules = {
      email: [
        {
          required: true,
          message: "请输入账号",
          trigger: "blur",
        },
      ],
      password: [
        {
          required: true,
          message: "请输入密码",
          trigger: "blur",
        },
      ],
    };
    const login = async () => {
      state.loading = true;
      form.value.validate((valid) => {
        if (valid) {
          service.login(state.loginForm).then((res) => {
            // console.log(res);
            if (res.data.errcode === 0) {
              state.loading = false;
              service.localStorageSave(res.data.data);
              router.push({ path: "home" });
              method.socketServe();
            } else {
              ElMessage.error(res.data.msg);
              state.loading = false;
            }
          });
        } else {
          state.loading = false;
        }
      });
    };
    onMounted(() => {});
    return {
      ...toRefs(state),
      login,
      form,
      rules,
    };
  },
};
</script>
<style>
input[type="password"]::-ms-reveal {
  display: none;
}
</style>
<style lang="scss" scoped>
input::-webkit-input-placeholder {
  color: #dbd6d6;
  font-size: 15px;
}

.el-input__inner {
  color: white !important;
  background-color: transparent !important;
  border: 1px solid #d4d4d4;
}
.el-input__inner :hover {
  border-color: #6331f0;
}
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  .loginLogo {
    width: 500px;
    flex: 2;
    background-color: yellowgreen;
    background: url("../assets/image/login_bg.png") no-repeat;
    background-size: 100% 100%;
  }
  .formContent {
    width: 100%;
    height: 100vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
    overflow: hidden;
    background-color: #24242b;
    align-items: center;
    .inputColor {
      color: white !important;
    }
  }
}
.formBody {
  width: 400px;
}
.logo {
  width: 220px;
  height: auto;
  overflow: hidden;
  margin-bottom: 40px;
  img {
    width: 100%;
  }
}
.el-form--label-top .el-form-item__label {
  display: block;
  text-align: left;
  color: white;
}
.title {
  color: white;
  font-size: 30px;
  list-style: none;
  text-align: left;
  margin-bottom: 40px;
}
.titleBot {
  width: 400px;
  color: gray;
  position: absolute;
  bottom: 80px;
}
.loginButton {
  width: 100%;
  background-color: #6331f0;
  border-color: #6331f0;
}
// .el-button--primary {
//   --el-button-font-color: #ffffff;
//   --el-button-background-color: #6331f0;
//   --el-button-border-color: #6331f0;
//   --el-button-hover-color: #6331f0;
//   --el-button-active-font-color: #6331f0;
//   --el-button-active-background-color: #6331f0;
//   --el-button-active-border-color: #6331f0;
// }
</style>
