<template>
  <el-container class="about">
    <el-header>
      <p class="goBack" @click="goBack">〈 返回</p>
      <div class="headerCol">
        <p class="headerLeft">
          <span class="text"> 订单列表</span>
          <span class="headercenter">
            <el-input
              v-model="search"
              placeholder="输入订单号"
              class="lineUpInput"
            >
              <template #append>
                <i class="searchI"></i>
                <el-button @click="goSearch"
                  ><img src="../assets/frame1.png" alt=""
                /></el-button>
              </template>
            </el-input>
          </span>
          <span class="headercenter">
            <el-date-picker
              v-model="goData"
              type="daterange"
              range-separator="-"
              :start-placeholder="yesterday"
              :end-placeholder="today"
              style="width: 300px"
              @change="getData"
            >
            </el-date-picker
          ></span>
        </p>
        <p class="headerRig"></p>
      </div>
    </el-header>
    <el-main>
      <el-card>
        <el-table :data="tableData" class="table" height="79vh">
          <el-table-column
            width="100px"
            align="center"
            prop="id"
            label="序号"
          />
          <el-table-column
            width="300px"
            align="center"
            prop="created_at"
            label="订单日期"
          />
          <el-table-column
            width="300px"
            align="center"
            prop="oid"
            label="订单号"
          />
          <el-table-column
            width="200px"
            align="center"
            prop="server_type_value"
            label="订单类型"
          >
            <template #default="scope">
              <span
                v-if="
                  scope.row.server_type_value == 1 ||
                  scope.row.server_type_value == 3
                "
                class="times"
              >
                <img src="../assets/time.png" alt=""
              /></span>
              <span
                v-if="
                  scope.row.server_type_value == 2 ||
                  scope.row.server_type_value == 5
                "
                class="oncecard"
              >
                <img src="../assets/oncecard.png" alt="" />
              </span>
              <span v-if="scope.row.server_type_value == 7" class="qingke">
                <img src="../assets/qingke.png" alt="" />
              </span>
            </template>
          </el-table-column>
          <el-table-column align="left" prop="surplus_num" label="人数" />
          <el-table-column
            width="200px"
            align="center"
            prop="state"
            label="状态"
            ><template #default="scope">
              <span
                v-if="scope.row.state == '0' || scope.row.state == '1'"
                class="lineUp"
              >
                <img src="../assets/lineup.png" alt=""
              /></span>
              <span class="games" v-if="scope.row.state == '2'">
                <img src="../assets/game.png" alt="" />
              </span>
              <span class="completes" v-if="scope.row.state == '3'"
                ><img src="../assets/complete.png" alt=""
              /></span>
            </template>
          </el-table-column>
          <el-table-column width="200px" align="center" label="操作">
            <template #default="scope">
              <el-button
                type="text"
                class="erweima"
                @click="handleAdd(scope.row, scope.row.id)"
                ><slot>二维码</slot></el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-row class="shop_page" style="float: right; margin-bottom: 10px">
          <el-pagination
            layout="total, prev, pager, next"
            :current-page="currentPage + 1"
            :page-size="pageSize"
            :total="totalItems"
            @current-change="onPaginationChange"
          ></el-pagination> </el-row
      ></el-card>
      <el-dialog
        v-model="orderDialog"
        custom-class="dialogq"
        center
        width="826px"
      >
        <el-header class="dialogTitle"
          ><span class="headerLef"
            >订单号:
            {{ codeNum }}
          </span></el-header
        >
        <div class="dialogImg">
          <div>
            <img :src="vxImg" />
          </div>
          <el-table :data="orderData" class="dialogTable" height="400">
            <el-table-column
              prop="order"
              label="序号"
              align="center"
              width="50px"
            />
            <el-table-column
              prop="avatar player_name"
              label="微信名"
              align="center"
              width="170px"
            >
              <template #default="scope">
                <span class="nameImg1" v-if="scope.row.avatar != ''">
                  <img :src="scope.row.avatar" />
                </span>
                <span v-else class="nameImg">
                  <img src="../assets/photo.png" />
                </span>
                <span class="nameUser">{{ scope.row.player_name }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              align="center"
              width="76px"
            >
              <template #default="scope">
                <span
                  v-if="scope.row.status == '0' || scope.row.status == '1'"
                  class="lineUp"
                  ><img src="../assets/lineup.png" alt="" />
                </span>
                <span v-if="scope.row.status == '2'" class="games"
                  ><img src="../assets/game.png" alt=""
                /></span>
                <span v-if="scope.row.status == '3'" class="completes"
                  ><img src="../assets/complete.png" alt=""
                /></span>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              width="66px"
              label="操作"
              align="center"
            >
              <template #default="scope">
                <span @click="remove(scope.row)" class="clearOrder"
                  >清除<img src="../assets/clear.png"
                /></span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import service from "@/utils/api";
import { computed, onMounted, watch } from "@vue/runtime-core";
import moment from "moment";
import { useStore } from "vuex";
import SocketService from "../utils/websocket";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const SF = SocketService.Instance;
    const router = useRouter();
    const state = reactive({
      orderData: [],
      vxImg: "",
      vxOrder: "",
      codeNum: "",
      currentPage: 0,
      pageSize: 10,
      totalItems: 0,
      yesterday: moment().subtract(6, "days").format("YYYY-MM-DD"),
      today: moment().format("YYYY-MM-DD"),
      orderDialog: false,
      goData: "",
      begin: "",
      end: "",
      search: undefined,
      tableData: [],
      manager_ver: "",
    });
    const store = useStore();
    // 第三步  引入
    const getOrderUser = computed(() => store.state.orderUser);
    // 监听
    watch(
      () => getOrderUser.value,
      (val) => {
        orderPlayers();
        // 调用接口刷新数据
      }
    );
    // 移除用户
    const remove = async (row) => {
      const params = {
        player_id: row.id,
      };
      service.playersUnbind(params).then((res) => {
        if (res.data.errcode == 0) {
          ElMessage.success("删除成功");
          orderPlayers();
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    };
    // 订单用户列表
    const orderPlayers = async () => {
      const params = {
        tid: state.vxOrder,
      };
      service.orderPlayers(params).then((res) => {
        if (res.data.errcode == 0) {
          state.orderData = res.data.data.map((item, index) => {
            return {
              order: state.codeNum.indexOf("LS") == 0 ? index + 1 : item.order,
              player_name: item.player_name,
              status: item.status,
              // avatar:
              //   item.avatar == ""
              //     ? "https://dj-public.nihaofuture.cn/manager/head.png"
              //     : item.avatar,
              avatar: item.avatar,
              id: item.id,
            };
          });
        }
      });
    };

    // 订单列表
    const orders = async () => {
      const params = {
        oid: state.search == "" ? undefined : state.search,
        begin: state.begin == "" ? undefined : state.begin,
        end: state.end == "" ? undefined : state.end,
        page: state.currentPage,
        size: state.pageSize,
      };
      service.ordersList(params).then((res) => {
        if (res.data.errcode == 0) {
          state.totalItems = res.data.total;
          state.tableData = res.data.data.map((item, index) => {
            return {
              id: item.id,
              created_at: moment(item.created_at).format("YYYY/MM/DD HH:mm"),
              oid: item.oid,
              order_type: item.order_type,
              order_state: item.order_state,
              surplus_num: item.surplus_num,
              qrcode: item.qrcode,
              state: item.state,
              server_type_value: item.server_type_value,
            };
          });
        }
      });
    };
    // 分页
    const onPaginationChange = async (page) => {
      state.currentPage = page - 1;
      await orders();
    };
    // 搜索订单号
    const goSearch = async (oid) => {
      // console.log(oid);
      oid = state.search;
      await orders();
    };
    // 日期选择器
    const getData = async () => {
      state.begin = state.goData[0].getTime();
      state.end = state.goData[1].getTime() + 24 * 60 * 60 * 1000 - 1;
      await orders();
    };
    // 返回
    const goBack = () => {
      router.push({ path: "/home" });
    };
    // 二维码
    const handleAdd = (item, id) => {
      state.orderDialog = true;
      state.vxImg = item.qrcode;
      state.vxOrder = item.oid;
      var oid = item.oid;
      var start = oid.substring(0, 2);
      var end = oid.substring(oid.length - 6);
      state.codeNum = start + "***" + end;
      orderPlayers();
      if (!SF.connected) {
        SocketService.Instance.connect();
      }
    };
    onMounted(() => {
      orders();
      state.manager_ver = localStorage.getItem("manager_ver");
    });
    return {
      ...toRefs(state),
      goBack,
      onPaginationChange,
      handleAdd,
      goSearch,
      getData,
      remove,
    };
  },
};
</script>

<style scoped lang="scss">
.about {
  background-color: #24242b;
  min-height: 100vh;
  color: white;
  box-sizing: border-box;
  padding: 20px;

  // 顶部
  .goBack {
    width: 50px;
    color: gray;
    font-size: 14px;
    cursor: pointer;
  }
  .headerCol {
    display: flex;
    justify-content: space-between;
    .headerLeft {
      font-size: 24px;
      font-weight: 900;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHgAAAAGCAYAAAAITFpjAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABUSURBVHgB7dHBDYAgEETRXT0bW9EOLEVDXQZbsQItBRpgCYQDLTCZd9ps5vbV7cGkSTIf/ltet0Uvamd9mj73v17l7Lc0hkkIGgODY2BwDAyOgcFlqi4Pjwiv2QQAAAAASUVORK5CYII=)
        no-repeat 0px 35px;
      background-size: 98px;
      .text {
        margin-right: 20px;
      }
      .headercenter {
        position: relative;
        margin: 0 11px;
        cursor: pointer;
        .lineUpInput {
          width: 250px;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .searchI {
          color: gray;
          position: relative;
          right: 10px;
          top: -5px;
          border-right: 2px solid gray;
          height: 20px;
        }
      }
    }
    .headerRig {
      width: 407px;
      height: 50px;
      background: url("../assets/group-background.png") no-repeat;
      opacity: 0.2;
    }
  }
  // 列表
  .el-card {
    background-color: #292931 !important;
    border: none;
    color: #ebebeb;
    min-height: 85vh;
    background: url("../assets/logo@2x.png") no-repeat 50% 55%;
    border-radius: 20px;
    margin-top: 13px;
  }
  .table {
    max-height: 78vh;
    color: #ebebeb !important;
    margin-bottom: 5px;
    font-size: 14px;
    .times {
      img {
        width: 75px;
        height: 22px;
      }
    }
    .qingke {
      img {
        width: 82px;
        height: 17px;
      }
    }
    .oncecard {
      img {
        width: 45px;
        height: 15px;
      }
    }
    .erweima {
      width: 66px;
      height: 36px;
      border: 1px solid #8d8d99;
      border-radius: 2px;
    }
  }
  .lineUp,
  .completes,
  .games {
    img {
      width: 56px;
      height: 12px;
    }
  }
  .table::-webkit-scrollbar {
    width: 0px;
    background: gray;
  }
  //弹出框
  .dialogTitle {
    display: flex;
    justify-content: space-between;
    color: #ebebeb;
    font-size: 36px;
    .headerLef {
      font-size: 36px !important;
    }
    .headRig {
      font-size: 14px !important;
    }
  }
  .dialogImg {
    display: flex;
    flex-direction: row;
    > div > img {
      width: 408px;
      height: 408px;
      margin: 20px;
    }
    .el-table__footer-wrapper,
    .el-table__header-wrapper {
      overflow: hidden;
      border: none;
    }
    .dialogTable {
      // width: 378px;
      color: #ebebeb !important;
      border: none !important;
      .nameImg {
        display: inline-block;
        width: 29px;
        height: 29px;
        padding: 1px;
        border-radius: 4px;
        background-color: whitesmoke;
        margin-right: 8px;
        text-align: left;
        img {
          width: 29px;
          height: 29px;
        }
      }
      .nameImg1 {
        img {
          width: 30px;
          height: 30px;
          border-radius: 4px;
        }
        margin-right: 8px;
      }
      .nameUser {
        display: inline-block;
        width: 110px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }

      .clearOrder {
        color: #8d8d99;
        cursor: pointer;
        img {
          width: 12px;
          height: 12px;
          display: inline-block;
          margin-left: 4px;
        }
      }
    }
  }
  .el-header {
    height: 60px;
    width: 100%;
    line-height: 30px;
  }
}
</style>
<style lang="scss">
.dialogq {
  width: 866px;
  border-radius: 20px !important;
  background-color: #454552 !important;
}
.dialogImg {
  .el-table__footer-wrapper,
  .el-table__header-wrapper {
    overflow: hidden;
    border-bottom: none;
  }
}
</style>
